import React from 'react'
import { useNavigate,useLocation } from 'react-router-dom'
import { GlobeEuropeAfrica,PersonFill,LockFill} from 'react-bootstrap-icons'



function Navbar() {
    const navigate = useNavigate()
   const location = useLocation()

const pathMatchRoute = (route) => {
    if(route == location.pathname){

        return true // Für Später dadurch weißt du ob du auf deiner Angecklickten Route bis 

    }

}

  return (
  
      <nav className='navbar fixed-bottom'>
    
            <i className='navbar__item navbar__item--active' onClick={()=> navigate('/')}> <GlobeEuropeAfrica size={30} className="navbar__icon"/></i>
        
            <i className='navbar__item navbar__item--active' onClick={()=> navigate('/menu')}><LockFill size={30} className="navbar__icon"/></i> 
           
            <i className='navbar__item navbar__item--active' onClick={() => navigate('/profil')}><PersonFill size={30} className="navbar__icon"/></i>
        
      </nav>
 
  )
}

export default Navbar
