"use client";

import React,{useState} from 'react'

import {APIProvider,Map,AdvancedMarker,Pin,InfoWindow} from '@vis.gl/react-google-maps'


function GoogleMapcomponent({data,position,position2}) {
   // const  [position,setPosition] = useState({})
   const [open, setOpen] = useState(false)


  return (
<>
   
     <APIProvider apiKey= "AIzaSyCBgP1X-KlxqeI8ExVbCyZLtZJ04_7Cc4w">
        <div style={{height: "300px", width: "100%"}}>
     <Map zoom={12}  center={position}  mapId={"a631f54a8f2f279f"}> 
     <AdvancedMarker position={position} onClick={() => setOpen(true)}>
        <Pin background={"white"} borderColor={"purple"} glyphColor={"purple"} />
     </AdvancedMarker>
     {open && <InfoWindow position={position} onCloseClick={() => setOpen(false)}><p>Ich</p></InfoWindow>}
     <AdvancedMarker position={position2} onClick={() => setOpen(true)}>
        <Pin background={"red"} borderColor={"red"} glyphColor={"white"} />
     </AdvancedMarker>
     {open && <InfoWindow position={position2} onCloseClick={() => setOpen(false)}><p>{data.location}</p></InfoWindow>}
     </Map>
     </div>
     </APIProvider>
     </>
  )
}

export default GoogleMapcomponent
