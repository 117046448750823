import React from 'react'
import {useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {getAuth, signInWithEmailAndPassword} from 'firebase/auth'
import {toast} from 'react-toastify'

function Signin() {
const [showPassword] = useState(false)
const [formData, setFormData] = useState({
  email: '',
  password: ''
})
const {email, password} = formData

const navigate = useNavigate()

const onChange= (e) => {
  setFormData((prevState) => ({
    ...prevState,
    [e.target.id]: e.target.value
  }))
    
}
const onSubmit = async(e) => {
  e.preventDefault()
  try{
  const auth =  getAuth()

  const userCredential = await signInWithEmailAndPassword(auth,email,password)
 
  if(userCredential.user){
    navigate('/profil')
  }
}catch(error){
toast.error("Bad User Credentials")

}
}


  return (
    <>
    <header className='header'>
      <h1 className='heading signIn__heading'>Welcome Back</h1>
    </header>
      
      <form onSubmit={onSubmit} className='signIn__form'>
        <input type="email" 
        placeholder='email'
         id="email" 
        value={email}
        onChange={onChange}
        className='signIn__input marginbottom-small'/>

        <input type={showPassword ? 'text': 'password'}
        placeholder='password'
        id="password"
         value={password}
         onChange={onChange}
         className='signIn__input '/>
      
      {/* <img src={some Src} alt={some alt} onClick={setShowPassword((prev) => !prev)}
        It is there to make the password visible but you dont have a picture yet*/}
    

    <button className='signIn__button margintop-medium'>Sign in</button>
      </form>
   <div className='links margintop-small'>
      <Link to='/forgot-password' className='link signIn__link '> Forgot Password</Link>

    <br/>
  { /*<OAuth />*/}
<Link to='/sign-up' className='link signIn__link  margintop-small'>Sign up page</Link>
</div>
    </>
  )
}

export default Signin
