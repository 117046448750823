import React from 'react'
import { getAuth,updateProfile } from 'firebase/auth'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { updateDoc,doc, collection,query,where,getDocs } from 'firebase/firestore'
import {db} from '../firebase.config'
import {Link } from 'react-router-dom'
import { PencilSquare } from 'react-bootstrap-icons'
import { Check2 } from 'react-bootstrap-icons'
import Myinvitations from '../components/Myinvitations'
import { toast } from 'react-toastify'



// Es es nur die Authentifizierung also das Logout eingebunden
function Profil() {
    const auth = getAuth()
const [changeDetails, setChangeDetails] = useState(false)
const [myInvitations, setMyInvitations] = useState(null)
const [loading, setLoading] = useState(true)
const [formData,setFormData] = useState({
    namePublisher: auth.currentUser.displayName,
    email: auth.currentUser.email,
})

const {namePublisher, email} = formData

const navigate = useNavigate()

useEffect(() => {

  
  const fetchInvitations = async() => {
   

    try{
      const auth = getAuth()
     
      const invitationsRef = collection(db,"Invitations")
      
      const q = query(invitationsRef, where('userRef','==',auth.currentUser.uid))
       


      const querySnap = await getDocs(q)
      console.log(querySnap)
    
      let invitations = []
    
    
     
      querySnap.forEach((doc) => {
    
       return invitations.push({
           id: doc.id,
           data: doc.data()
       })
     })

      console.log("Das sind: ",invitations)
      setMyInvitations(invitations)
      setLoading(false)
    
    }catch(error){
    setLoading(false)


    }
       


  }
 fetchInvitations();
 

},[])


 const onLogout = () => {
    auth.signOut()
    navigate('/')
 }

const onSubmit = async() => {

  
    try{

        if(auth.currentUser.displayName !== namePublisher){
            //Update display name in fb 
            await updateProfile(auth.currentUser, {
                displayName: namePublisher
            })

            //Update in firestore
            const userRef = doc(db, 'users', auth.currentUser.uid)
            await updateDoc(userRef,{
            namePublisher
     })
        }
    }catch(error){
  

      toast.error('Error please try again later')
      

    }

}
const addFriends = () => {
   navigate('/Friends')
}
const requestedFriends = () => {

    navigate('/request')
}




const onChange = (e) => {
  setFormData((prev) => ({
    ...prev,
    [e.target.id]: e.target.value,
  }))
}


 if(loading){
  return <h3 className='loading'>loading..</h3>
 }



  return  <div className='profil-page'>
    <header className='header'>
    <h1 className='heading profil__heading'>My Profil</h1>
    </header>
    <div className='center__smaller'>
        <h3 className='text profil__text'>Personal Details</h3>
       
    </div>
   <div className='center__smaller'>
    
    <form className='profil'>

   <input type='text' id='namePublisher' disabled={!changeDetails} value={namePublisher} onChange={onChange} className='profil__input'/> 
   <i className='text' onClick={() => {changeDetails && onSubmit() 
            setChangeDetails((prevState) => !prevState)}}>{changeDetails ? <Check2 size={25} className='profil__icon'/>: <PencilSquare size={25} className='profil__icon'/>}</i> 
   </form>
   </div>
{/*
    <button type='button' onClick={addFriends}>Add friends</button>
    <button type='button' onClick={requestedFriends}>Requested</button>
        */}
    <div className='center__smaller'>
    <Link to='/createinvitation' className='link margintop-small profil__link'>Create Your own Invitations</Link>
    </div>
    
    { !loading && myInvitations.length > 0 ? myInvitations.map((myInvitation) => <Myinvitations key={myInvitation.id} data={myInvitation.data} id={myInvitation.id}/>) :<div className='profil__divCenter'> <h2 className='profil__text__NOINV'>You have no Invitations created</h2></div> }
    
   <div className='form pofil__form'>
    <button type="button" onClick={onLogout} className='button margintop-small profil__button center'>Logout</button>
    </div>
  </div>
}

export default Profil
