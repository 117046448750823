import React from 'react'
import { useEffect,useState } from 'react'
import {getAuth, onAuthStateChanged} from 'firebase/auth'

export const useAuthStatus = () => {
const [loggedIn, setLoggedIn] = useState(false)
const [checkingStatus, setCheckingStatus] = useState(true)


//Du hast das Mounted ding nicht gemacht vidio 91 Private Route
useEffect(() => {
    const auth = getAuth()
    onAuthStateChanged(auth, (user) => {
        if(user){
            setLoggedIn(true)
          
        }
        setCheckingStatus(false)
    })
})


  return {loggedIn, checkingStatus}
}

