import React from 'react'
import { useEffect, useState, useContext} from 'react'
import {collection, connectFirestoreEmulator, getDocs, query, where} from 'firebase/firestore'
import {db} from '../firebase.config'
import PlacesAutocomplete from '../components/PlacesAutocomplete'
import SearchContext from '../context/SearchContext'
import getDistance from 'geolib/es/getDistance'
import Invitation from '../components/Invitation'
import {toast} from 'react-toastify'
import ReactGA from 'react-ga4'
import Backdrop from '../components/Backdrop'
import { Back, Backspace } from 'react-bootstrap-icons'
import Defaultinvitation from '../components/Defaultinvitation'

function Globalinvitations() {
  const [invitations, setInvitations] = useState([])
  const [defaultInvs, setDefaultInvs] = useState([])
const [position,setPosition] = useState({})
 const [searched, setSearched] = useState(true)
 const {gotClicked,setGotClicked,search,empty,setEmpty} = useContext(SearchContext)
const [range, setRange] = useState(6)
const [onlyTodaysInvitation, setOnlyTodaysInvitations] = useState(false)




 useEffect(() => {
  console.log("GEts called")
  ReactGA.send({hitType: "pageview", page: "/", title: "globalInvitation"})


  var position2 = {}
  const fetchCoordinates = async() => {
    try{


    const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${search}`)
    const data = await response.json()
    //console.log(search)
    setPosition({ lat: parseFloat(data[0].lat),
                  lng: parseFloat(data[0].lon)})
    
  

  
   /*const location = data.status === 'ZERO_RESULTS' ? undefined : data.results[0]?.formatted_address
     if( location === undefined || location.includes('undefined')){
      
        toast.error("Please enter a real address")
        
        return
     }
*/
     position2 ={lat:data[0].lat ,
      lng: data[0].lon }
    //console.log("position 2: " + position2.lat,position2.lng,  data.results[0]?.formatted_address)
  
    }catch(error){
     
        console.log(error)
        
      
    }
   
  
   }

  if(!gotClicked)return;

    const fetchInvitations = async () => {

    try{





   const invitationsRef = collection(db,"Invitations")

   // Create a query
   const q = query(invitationsRef, where('privat','==',true))

   //Excute query
   const querySnap = await getDocs(q)
    
   let invitations = []
   try{
   const response = await fetch(`https://protected-reef-68206-6c4ba84bb16e.herokuapp.com/newgoogleapi?latitude=${position2.lat}&longitude=${position2.lng}&radius=${(range * 1000)}`)
   const defaultINV = await response.json()
  

  
try{
  if(defaultINV[0].error_message   ){
    console.log("leer")
    setEmpty(true)
    return
  }else{
  setEmpty(false)
  }
}catch(error){
   
}

   var filterdefaultInv = defaultINV.filter((inv) => inv.result.current_opening_hours !== undefined)
  filterdefaultInv = filterdefaultInv.filter((inv) => inv.result.photos !== undefined)
  filterdefaultInv = filterdefaultInv.filter((inv) => inv.result.photos[0] !== undefined)
  filterdefaultInv = filterdefaultInv.filter((inv) => inv.result.photos[1] !== undefined)
   
   
     if(onlyTodaysInvitation){
      const today = new Date()
      const todayFilterDefault = []
       filterdefaultInv.forEach((filterinv) => {
       const checkDay = new Date(filterinv.result.current_opening_hours.periods[0].open.date)
         if(checkDay.getDate() === today.getDate()){
          todayFilterDefault.push(filterinv)
            
         }
       })
      if(todayFilterDefault.length === 0){
        setEmpty(true)
      }
       setDefaultInvs(todayFilterDefault)
  

     }else{
   setDefaultInvs(filterdefaultInv)
     }

   }catch(error){
    console.log(error)
   }
   querySnap.forEach((doc) => {
    if((range * 1000) >= getDistance(
      { latitude: position2.lat, longitude: position2.lng },
      { latitude: doc.data().geolocation.lat, longitude: doc.data().geolocation.lng }
  )){

    const today = new Date()
    const checkDay = new Date(doc.data().startTimeDate)
    if(checkDay.getDate() >= today.getDate()){

      if(onlyTodaysInvitation){
     if(checkDay.getDate() === today.getDate()){
        
      return invitations.push({
        id: doc.id,
        data: doc.data()
    })
    }

      }else{
      return invitations.push({
        id: doc.id,
        data: doc.data()
    })
  

  }

    }

  }
   })
   
   setInvitations(invitations)
   

    }catch(error){
        toast.error("Could not fetch Invitations")

    }

    }

// Fetch Coordinates from Searvh
fetchCoordinates().then(fetchInvitations)
    
  
 
  setGotClicked(false)
  setSearched(false)

},[gotClicked,range,onlyTodaysInvitation])

const onChange = (e) => {

  setRange(e.target.value)
  setGotClicked(true)

}

const onClick = () => {

  setOnlyTodaysInvitations((prev) => !prev)
  setGotClicked(true)
}






  return (
    
    <div className='globalInvitations'>
         <PlacesAutocomplete />
  
    <div className='globalInvitations__body'>
     { !searched && <div className='globalInvitations__rangediv'>
      <p className='globalInvitations__text'>Your search range: {range} km</p>
     <input type="range" min='0' max='10' step='1' value={range} onChange={onChange} className='globalInvitations__range' />
     <button type="toggle" className=' globalInvitations__button' onClick={onClick}>{onlyTodaysInvitation ? 'Show all' : 'Only today'}</button>
    { /*<p className='globalInvitations__text'>Select a category that fits your interest</p>
     <Backdrop id="categoryChoice" value={categoryChoice} options={["All","All black","All white","Ladies night","ü30"]} multiple={true} onMutate={handelCategoryChoice}/>
     <p className='globalInvitations__text'>Selet a music category that fits your interest</p>
  <Backdrop id="musicChoice" value={musicChoice} options={["All","Techno","Rock","Hip-Hop"]} multiple={true} onMutate={handelMusicChoice}/> */}
          </div>}
      {searched  ? (<h1 className='globalInvitations__headingtext'>Search for clubs, partys and other invitations in your area, or sign-up and create your own invitations</h1>): invitations && invitations.length && !empty > 0 || defaultInvs.length > 0 && !empty ?(<>
      <ul className='Invitation__list'>
        {invitations.map((invitation) => <Invitation  key={invitation.id} data={invitation.data} position={position}/>)}
        {defaultInvs.map((defaultInv) => <Defaultinvitation key={defaultInv.result.place_id} data={defaultInv.result} position={position}/>)}
      </ul>
      </>): invitations.length === 0 && empty ? ( <p className='globalInvitations__headingtext'>No Invitations (Please add an address, which is as specific as possible)</p>):(<p className='globalInvitations__headingtext'>Loading...</p>)}
      </div>
    </div>
    
      
  )
}

export default Globalinvitations
