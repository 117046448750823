import React from 'react'
import {useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {getAuth, createUserWithEmailAndPassword, updateProfile} from 'firebase/auth'
import {db} from '../firebase.config.js'
import {setDoc,serverTimestamp,doc,collection,query,where,getDocs } from 'firebase/firestore'
import {toast} from 'react-toastify'

function Signup() {
const [showPassword, setShowPassword] = useState(false)

const [formData, setFormData] = useState({
  namePublisher:'',
  email: '',
  password: '',
  friends: [],
  requests: []
})
const {namePublisher,email, password} = formData

const navigate = useNavigate()

const onChange= (e) => {


  setFormData((prevState) => ({
    ...prevState,
    [e.target.id]: e.target.value
  }))
    
}

const onSubmit = async (e) => {
    e.preventDefault()




const auth = getAuth()

//New code
try{
const usersRef = collection(db,'users')

const q = query(usersRef, where('namePublisher', '==',namePublisher))

const querySnap = await getDocs(q)

console.log(querySnap)
if(querySnap.empty){
 
 
  try{


    const userCredential = await createUserWithEmailAndPassword(auth, email, password )

const user = userCredential.user

updateProfile(auth.currentUser, {
    displayName: namePublisher
})


const formDataCopy = {...formData}
 delete formDataCopy.password
 formDataCopy.timestamp = serverTimestamp()


 await setDoc(doc(db, 'users', user.uid), formDataCopy)

navigate('/profil')

}catch(error){
  console.log(error)
toast.error("Something went wrong")
}


}else {
 
  toast.error("Name is already taken")
  

}





}catch(error){

  console.log("Error mit check name")
}

//works


}
  return (
    <>
    <header className='header'>
      <h1 className='heading signUp__heading'>Welcome to uenvited</h1>
    </header>
      
      <form onSubmit={onSubmit} className='signUp__form'>
      <input type="text" 
        placeholder='name'
         id="namePublisher" 
        value={namePublisher}
        onChange={onChange} 
        className='signUp__input marginbottom-tiny'/>

        <input type="email" 
        placeholder='email'
         id="email" 
        value={email}
        onChange={onChange}
        className='signUp__input marginbottom-tiny'/>

        <input type={showPassword ? 'text': 'password'}
        placeholder='password'
        id="password"
         value={password}
         onChange={onChange} 
         className='signUp__input marginbottom-small'/>
      
      {/* <img src={some Src} alt={some alt} onClick={setShowPassword((prev) => !prev)}
        It is there to make the password visible but you dont have a picture yet*/}

<button className=' marginbottom-small signUp__button'>Sign up</button>

      </form>
  
{ /*<OAuth/>*/}
<div className='links '>
<Link to='/forgot-password' className='link marginbottom-small signUp__link'> Forgot Password</Link>
<br/>
<Link to='/sign-in' className='link signUp__link'>Sign-in page</Link>
</div>
    </>
  )
}

export default Signup
