import React,{useState} from 'react'
import { useCookies } from 'react-cookie'
import ReactGA from "react-ga4"
import { Link } from 'react-router-dom'
import { ChevronCompactDown,ChevronCompactUp } from 'react-bootstrap-icons'


function CookieBanner() {
const [cookies, setCookie] = useCookies(["cookieConsent"])  
const [show, setShow] = useState(true)
const [clicked, setClicked] = useState(false)

const accept = () => {
    const initializeGoogleAnalytics = () => {
        const MESS_ID = "G-QQW406Z66C"
       ReactGA.initialize(MESS_ID)
       
       }
       initializeGoogleAnalytics()
       setCookie("cookieConsent", true, {path: "/"})

       
}
const decline = () => {
    setShow(false)
}

const onClick = () => {
  setClicked((prevState) => !prevState)
}

if(!show){
    return (<></>)
}

  return (
    <div className='cookie-banner__full'>
    <div className='CookieBanner'>
      <h4 className='cookie-banner__heading'>Cookiebanner</h4>
       <p className='CookieBanner__text'>We use Cookies for Google Anlytics, please accept, if you want to learn more about how we use data please click privacy policy</p>
       <div className='CookieBanner__buttons'>
       <button className='CookieBanner__buttonaccept' onClick={accept}>Accept</button>
       <button className='CookieBanner__buttondecline' onClick={decline}>Decline</button>
       </div>
       
       <button onClick={onClick} className='CookieBanner__showmorebutton'> Privacy Policy {clicked ? <ChevronCompactUp size={20} className='CookieBanner__icon'/>: <ChevronCompactDown size={20} className='CookieBanner__icon'/>}</button>

    </div>
        {clicked &&  <div className='CookieBanner__privacyPolicy'>
         
        <h3>Privacy Policy and Data security</h3>

<h4>Data Collection Methods </h4>
 <p>We utilize and store your email address for authentication purposes and do not use it for advertising or any related activities. Additionally, we use Cookies for functionality on our website. 
   Furthermoree we use a firebase Account for storing user data</p>
 <p>All information that you enter will be publicly visible, everyone can read and store Data from our Website.</p>
 <p>Any images and text that you provide are stored in our database and processed solely for the functional purposes of our website. We do not utilize any of this information for other purposes</p>

 <h4>Use of Collected Information</h4>
 <p>We use Cookies for Google Analytics and to track user acceptance of the Cookie consent banner. The data collected through Cookies is used exclusively to enhance the functionality of our website and is not utilized for any other purposes.</p>
 
<h4>Opting In and Out of Data Collection</h4>
<p>Users have the option to decline the Cookie banner, thereby opting out of the use of Cookies. Furthermore, if a user solely utilizes our homepage with the search bar, no data is collected, even through Cookies.</p>
 
<h4>Data Deletion Procedures </h4>
<p>Cookies are automatically deleted after a certain period of time. Users also have the ability to decline the cookie banner to prevent data collection. Additionally, any information provided by users is deleted from our server with no backup retention.</p>

<h3>Updating These Terms</h3>
<p>We may change our Website and policies, and we may need to make changes to these Terms so that they accurately reflect our Website and policies. We will only make changes if the provisions are not appropriate anymore or if they are incomplete, and only if the changes are reasonable for you in consideration to your interests. Unless otherwise required by law, we will notify you of the changes and choices you have (for example, through our Website) at least 30 days before we update the Terms, and we will give you an opportunity to review them before they go into effect. Then, if you continue to use the Website, you will be bound by the updated Terms.</p>

</div> }
 </div>
  )
}

export default CookieBanner
