import React from 'react'
import { Link } from 'react-router-dom'

function Menu() {
  return (
    <div className='menu'>
      <Link to='/privacypolicy' className='menu__linkPrivacy'>Privacy Policy</Link>
      <Link to='/legal' className='menu__linkLegal'>Legal</Link>
      <Link to='/termsofuse' className='menu__linkTermofuse'>Terms of use</Link>
      <Link to='/creditpage' className='menu__link-credit'>Websites we want to give credits</Link>
    </div>
  )
}

export default Menu
