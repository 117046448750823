import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Globalinvitations from "./pages/Globalinvitations";
import Profil from "./pages/Profil";
import Signin from "./pages/Signin";
import Signup from "./pages/Signup";
import Navbar from "./components/Navbar";
import PrivateRoute from "./components/PrivateRoute";
import Forgotpassword from './pages/Forgotpassword';
import Menu from "./pages/Menu";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Legal from "./pages/Legal";
import Termsofuse from "./components/Termsofuse";
import Termsofusepage from "./pages/Termsofusepage";
import CreateInvitations from "./pages/CreateInvitations";
import CookieBanner from "./components/CookieBanner";
import {SearchProvider} from './context/SearchContext'
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useCookies } from "react-cookie";
import Creditpage from "./pages/Creditpage";


function App() {
const [cookies] = useCookies(["cookieConsent"])

 

   
  return (
    <>
    <SearchProvider>


    <Router>
      <Routes>
      <Route path='/' element={<Globalinvitations />} />
      <Route path='/menu' element={<Menu/>}/>
      <Route path='/creditpage' element={<Creditpage />} />
      <Route path='/privacypolicy' element={<PrivacyPolicy cookieConsent={cookies.cookieConsent}/>}/>
      <Route path='/termsofuse' element={<Termsofusepage/>} />
      <Route path='/legal' element={<Legal/>}/>
      <Route path='/sign-in' element={<Signin />} />
      <Route path='/sign-up'  element={<Signup />} />
    
      <Route path="/profil" element={<PrivateRoute />}>
      <Route path="/profil" element={<Profil />} />
      </Route>
      <Route path="/forgot-password" element={<Forgotpassword/>}/>
      <Route path="/createinvitation" element={<PrivateRoute />}>
      <Route path="/createinvitation" element={<CreateInvitations />} />
      </Route>
      </Routes>
    <Termsofuse />
    {!cookies.cookieConsent && <CookieBanner/>}
     <Navbar/>
    </Router>
   
    </SearchProvider>
     <ToastContainer theme="dark"/>
    </>
  );
}

export default App;
