import React from 'react'

function Legal() {
  return (
    <div className='legal'>
      <h2 className='legal__heading'>Contact: </h2>
      <p className='legal__paragraph'>Name: Adrian Dubois</p>
      <p className='legal__paragraph'>Email: adrian.dubois@uenvited.com</p>
      <p className='legal__paragraph'>Country: Germany, bayern</p>

      <h2 className='legal__heading'>Website Disclaimer</h2>
      <p className='legal__paragraph'>Website disclaimer is Adrian Dubois</p>
      <p className='legal__paragraph'>to contact please see contact</p>
    </div>
  )
}

export default Legal
