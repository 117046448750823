import React, { useEffect } from 'react'
import GoogleMapcomponent from './GoogleMap'
import { useState } from 'react'
import { ChevronCompactDown,ChevronCompactUp,XLg } from 'react-bootstrap-icons'
import Popup from './Popup'
import axios from 'axios'

function formatTime(timeString) {
    // Extrahieren von Stunden und Minuten aus dem String
    const hours = timeString.slice(0, 2);
    const minutes = timeString.slice(2);

    // Formatieren und Zusammenführen von Stunden und Minuten mit einem Doppelpunkt
    const formattedTime = `${hours}:${minutes}`;

    return formattedTime;
}


function Defaultinvitation({data, position}) {
    const [clicked, setClicked] = useState(false)
    const [showPopup, setShowPopup] = useState(false)
    const [imgUrls, setImgUrls] = useState(null)
    const [url, setUrl] = useState('')
    const [url2, setUrl2] = useState('')
  
    const fetchFotosFiles = async(photo_reference,url) => {
        try{
      
           const response = await fetch(`https://protected-reef-68206-6c4ba84bb16e.herokuapp.com/get-image-url?photo_referenz=${photo_reference}`);
            const data2 = await response.json()
            url(data2)

              // Antwort verarbeiten
             
        }catch(error){
            console.log(error)
   
        }    
    }



  
  if(data.photos !== undefined && data.photos[0].photo_reference !== undefined && data.photos[1].photo_reference !== undefined){
 //   url =`http://localhost:3003/photo?photo_reference=${data.photos[0].photo_reference}`
  //   url2 =`http://localhost:3003/photo?photo_reference=${data.photos[1].photo_reference}`
  
  fetchFotosFiles(data.photos[0].photo_reference,setUrl)
  fetchFotosFiles(data.photos[1].photo_reference,setUrl2)
  console.log(data.photos[0].html_attributions[0])
 /* if(data.photos[0].html_attributions){
    console.log("Bin hier")
      setSource(data.photos[0].html_attributions[0])
    }
    if(data.photos[1].html_attributions){
        setSource2(data.photos[1].html_attributions[0])
      }*/
  }

  

var startDate = new Date(data.current_opening_hours ? data.current_opening_hours.periods[0].open.date : undefined)
 startDate = startDate.getDate() +"."+ (startDate.getMonth() + 1) +"."+startDate.getFullYear()
var endDate = new Date(data.current_opening_hours ? data.current_opening_hours.periods[0].close.date : undefined)
endDate = endDate.getDate() +"."+ (endDate.getMonth() + 1)+"."+endDate.getFullYear()


    const onClick = () => {
  setClicked((prevState) => 
      !prevState
  )
  
  
    }
    
    var startTime = formatTime(data.current_opening_hours ? data.current_opening_hours.periods[0].open.time : '')
    var endTime = formatTime(data.current_opening_hours ? data.current_opening_hours.periods[0].close.time : '')


    const popUp = (imgUrls) => {
      setImgUrls(imgUrls)
      setShowPopup(true)
  
      return(
          <Popup pic={imgUrls} data={data}/>
      )
  }
  
  
  const close = () => {
      setShowPopup(false)
  }
  
  
  
     return (
       
      <div className='Invitation' style={{background: `linear-gradient(-20deg,black 40%, purple)`}}>
   
      <div className='Invitation__top'>
       <div onClick={() => popUp(url)} className='Invitation__image' >
       <img className="Invitation__img" src={url} />
       </div>
        { /*<img className="Invitation__img" src={data.imgUrls[0]} alt="Uploaded picture"/> */}
          
          <div className='Invitation__texte'>
        <h3 className="Invitation__heading">  {data.name}</h3>
        <p className='Invitation__text'> Category: Nacht Club</p>
        <p className="Invitation__text"> Music: /</p>
      <p className='Invitation__text'>Start time: {startTime}</p>
      <p className='Invitation__text'>Start Date: {startDate}</p>

        </div>
        
      <button onClick={onClick} className='Invitation__button'> {clicked ? <ChevronCompactUp size={20} className='icon'/>: <ChevronCompactDown size={20} className='icon'/>}</button>
  
        </div>
  
      {clicked &&  (<div className='Invitation__bottom'>
      <p className='Invitation__text'>More additions: No extra additions for this Invitation</p>
      <p className='Invitation__text'>End Date: {endDate}</p>
      <p className='Invitation__text'>End time: {endTime}</p>
      <p className='Invitation__text'>Price: /</p>
      <p className="Invitation__text">Minimum age: /</p>
      {data.photos && <div onClick={() => popUp(url2)} className='Invitation__image2' >
    <img className="Invitation__img" src={url2} />
    </div>}
      <GoogleMapcomponent position={position}  data={data} position2={data.geometry.location} className="Invitations__karte"/>
      <p className='Invitation__text'>Addresse: {data.vicinity}</p>     </div>
      )}
     {showPopup &&<div> <Popup pic={imgUrls} data={data}/><XLg size={50} className='popUp__button' onClick={close} /> </div>}
      </div>
    )
}

export default Defaultinvitation

