import React from 'react'
import GoogleMapcomponent from './GoogleMap'
import { useState } from 'react'
import { ChevronCompactDown,ChevronCompactUp,XLg } from 'react-bootstrap-icons'
import Popup from './Popup'

function Invitation({data ,position}) {
  const [clicked, setClicked] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const [imgUrls, setImgUrls] = useState(null)

var startDate = new Date(data.startTimeDate)
var endDate = new Date(data.endTimeDate)
startDate = startDate.getDate() + "." + startDate.getMonth() +1  + "." + startDate.getFullYear()
endDate = endDate.getDate() + "." + endDate.getMonth() + 1 + "." + endDate.getFullYear()


  const onClick = () => {
setClicked((prevState) => 
    !prevState
)


  }

  const popUp = (imgUrls) => {
    setImgUrls(imgUrls)
    setShowPopup(true)

    return(
        <Popup pic={imgUrls} data={data}/>
    )
}


const close = () => {
    setShowPopup(false)
}



   return (
    <div className='Invitation' style={{background: `linear-gradient(-20deg,black 40%,${data.backgroundcolor})`}}>
 
    <div className='Invitation__top'>
        
     <div onClick={() => popUp(data.imgUrls[0])} className='Invitation__image' >
     <img className="Invitation__img" src={data.imgUrls[0]} alt="Uploaded picture"/>
     </div>
      { /*<img className="Invitation__img" src={data.imgUrls[0]} alt="Uploaded picture"/> */}
        
      
        <div className='Invitation__texte'>
      <h3 className="Invitation__heading">  {data.name}</h3>
      <p className='Invitation__text'> Category: {data.category}</p>
      <p className="Invitation__text"> Music: {data.music}</p>
      <p className='Invitation__text'>Start time: {data.startTimeTime}</p>
      <p className='Invitation__text'>Start date: {startDate}</p>
      </div>
      
    <button onClick={onClick} className='Invitation__button'> {clicked ? <ChevronCompactUp size={20} className='icon'/>: <ChevronCompactDown size={20} className='icon'/>}</button>

      </div>

    {clicked &&  (<div className='Invitation__bottom'>
    <p className='Invitation__text'>More additions: {data.additions}</p>
    <p className='Invitation__text'>End date: {endDate}</p>
    <p className='Invitation__text'>End time: {data.endTimeTime}</p>
    <p className='Invitation__text'>Price: {data.admission}</p>
    <p className="Invitation__text">Minimum age: {data.age}</p>
    <p className='Invitation__text'>Publisher: {data.publisher}</p>
    {data.imgUrls[1] && <div onClick={() => popUp(data.imgUrls[1])} className='Invitation__image2' >
    <img className="Invitation__img" src={data.imgUrls[1]} />
    </div>}

    <GoogleMapcomponent position={position}  data={data} position2={data.geolocation} className="Invitations__karte"/>
   </div>
    )}
   {showPopup &&<div> <Popup pic={imgUrls} data={data}/><XLg size={50} className='popUp__button' onClick={close} /> </div>}
    </div>
  )
}

export default Invitation
