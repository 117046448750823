import React from 'react'

function Popup({pic,children}) {

  return (
    <>
    <div className='popUp__background'></div>
    <img src={pic} className='popUp' />
  
   
    </>
  )
}

export default Popup
