import React from 'react'
import {useState, useEffect,useRef} from 'react'
import {getAuth, onAuthStateChanged} from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import {getStorage, ref,uploadBytesResumable,getDownloadURL} from 'firebase/storage'
import {v4 as uuidv4} from 'uuid'
import {db} from '../firebase.config'
import { addDoc,collection } from 'firebase/firestore'
import {serverTimestamp,} from 'firebase/firestore'
import {toast} from "react-toastify"
import Backdrop from '../components/Backdrop'

function CreateInvitations() {
    const [geolocationEnabled] = useState(true) 
    const [loading, setLoading] = useState(false)
    const [user, setUser] = useState({})
   const  [formData, setFormData] = useState({
    additions: '',
    age: 18,
    endTimeDate: '',
    endTimeTime: '',
    latitude : 0,
    longitude: 0,
    images: {},
    address: '',
    name:'',
    privat: false,
    startTimeDate: '',
    startTimeTime: '',
    admission: '',
    status: 'in progress',
    music: 'none',
    category: 'none',
    backgroundcolor: 'black'
   })

const {startTimeDate,startTimeTime,endTimeDate,endTimeTime, admission,additions,age,latitude,longitude,images,address,name,music,category,backgroundcolor} = formData

   const auth = getAuth()
   const navigate = useNavigate()
   const isMounted = useRef(true)
 
   useEffect(()=>{
if(isMounted){
    onAuthStateChanged(auth,(user) =>{
        if(user){
            setUser(user)
            setFormData({...formData, userRef: user.uid,publisher: user.displayName})
        }else{
            navigate('/sign-in')
        }
    
    
    })
}



return () => {
    isMounted.current = false
}

   },[isMounted])

const onSubmit = async(e) =>{
e.preventDefault()

setLoading(true)
if(images.length > 3){
    setLoading(false)
    toast.error('You can only have 2 images')
    return
}
let geolocation = {}
let location 

if(geolocationEnabled){
  
  const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${address}`)
  const data = await response.json()
  
  if(data[0] === undefined){
    toast.error('Please add a real address')
    setLoading(false)
    return
  }
  
    geolocation.lat= parseFloat(data[0].lat)
    geolocation.lng = parseFloat(data[0].lon)

}else{
    geolocation.lat = latitude
    geolocation.lng = longitude

   
}

// Store images in firebase

const storeImage = async(image) => {
    return new Promise((resolve,reject) => {
        const storage = getStorage()
        const fileName = `${auth.currentUser.uid}-${image.name}-${uuidv4()}`

        const storageRef = ref(storage, 'images/' + fileName)

        const uploadTask = uploadBytesResumable(storageRef, image)

        uploadTask.on('state_changed', 
  (snapshot) => {
    // Observe state change events such as progress, pause, and resume
    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;                                           //Das wurde aus Kommentiert
    switch (snapshot.state) {
      case 'paused':
        break;
      case 'running':
        break;
        default: console.log("Default case");
    }
  }, 
  (error) => {

    reject(error)
  }, 
  () => {
    // Handle successful uploads on complete
    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
      resolve(downloadURL);
    });
  }
);

    })
}
const imgUrls = await Promise.all(
    [...images].map((image) => storeImage(image))
).catch(() => {
    setLoading(false)
    toast.error("Images could not upload")

    return
})

const formDataCopy = {
    ...formData,
      imgUrls,
      geolocation,
      timpstamp: serverTimestamp()
}

formDataCopy.location = address
delete formDataCopy.images
delete formDataCopy.address

const docRef = await addDoc(collection(db, 'Invitations'),formDataCopy)                      //Hier hast du aus Kommentiert




setLoading(false)

toast.success('Invitation in progress')
navigate('/')
}

const onMutate = (e) =>{

  let boolean = null


  if(e.target.value === 'true'){
    boolean = true
  }
  if(e.target.value === 'false'){
    boolean = false
  }

  // Files
  if(e.target.files){
    setFormData((prevState) => ({
         ...prevState,
         images: e.target.files

    }))
  }
if(!e.target.files){
    setFormData((prevState) => ({
        ...prevState,
        [e.target.id]: boolean ?? e.target.value
    }))
}

  //Text/Booleans/Number
}

   if(loading){
    return <h3 className='loading'>loading...</h3>
   }

  return (
    <div>
      <header className="header">
      <h1 className='heading'>Create an Invitation</h1>
      </header>
      <form onSubmit={onSubmit} className='createInvitations__form'>
        <div className='createInvitations'>
        <p className='createInvitations__text'>The publisher is {user.displayName}</p>
        <p className='createInvitations__text'>Give your Event/Party a title </p>
        <input type='text' id='name' value={name} onChange={onMutate} className='createInvitations__input' required/>
        <p className='createInvitations__text'>Please choose the category of your event</p>
        <Backdrop id='category' value={category} options={["none","All black", "All white", "Ladies night", "ü30","Festivals","open air","outdoor","beach party","club night","bar night"]} onMutate={onMutate} multiple={false} className="createInvitations__input"/>
        <p className='createInvitations__text'>Please choose the music on your event</p>
        <Backdrop id='music' value={music}  options={["none","Techno", "Rock", "Hip-Hop","Pop","R&B","Indie","Electronic","Jazz","Country","Classic"]} onMutate={onMutate} multiple={false} className="createInvitations__input"/>
        <p className='createInvitations__text'>Add additions to your invitation</p>
      <input type='text' id='additions' value={additions} onChange={onMutate} className='createInvitations__input' required/>
        <p className='createInvitations__text'>Which is the required age for your party, please choose an age 18 or older</p>
        <input type='number' id='age' min="18" value={age} onChange={onMutate} className='createInvitations__input__number'  required/>
        <p className='createInvitations__text'>On which date does your Party start</p>
        <input type='date' id='startTimeDate' value={startTimeDate} onChange={onMutate} className='createInvitations__input' required />
        <p className='createInvitations__text'>At what time does your party start, please choose between 21:30-07:00</p>
        <input type='time' id='startTimeTime' min='21:30' max="07:00" value={startTimeTime} onChange={onMutate} className='createInvitations__input' required/>

        <p className='createInvitations__text'>On which date does your Party end</p>
        <input type='date' id='endTimeDate' value={endTimeDate} onChange={onMutate} className='createInvitations__input' required />
        <p className='createInvitations__text'>At what time does your party end, please choose between 21:30-07:00</p>
        <input type='time' id='endTimeTime' min="21:30" max="07:00" value={endTimeTime} onChange={onMutate} className='createInvitations__input' required/>

        <p className='createInvitations__text'>What will your party cost, please add your curreny</p>
        <input type='text' id='admission' value={admission} onChange={onMutate} className='createInvitations__input'  required/>
        

        <p className='createInvitations__text'>Where is your Party going to be, please add a real address</p>
        <input type='text' id='address' value={address} onChange={onMutate} className='createInvitations__input' required />
        {!geolocationEnabled && (
            <div>
                <p className='createInvitations__text'>Your geolocation is not enabled you can type longitude and latitude to post your Location</p>
            <input type='number' id='latitude' value={latitude} onChange={onMutate} className='createInvitations__input' required /> 
            <input type='number' id='longitude' value={longitude} onChange={onMutate} className='createInvitations__input' required/>
            </div>
        )}
  
    {/*<div>
      <p className='createInvitations__text'>Do you want your Party to be private</p>
        <button className="button" type='button' id='privat' value={true} onClick={onMutate}>Yes</button>
        <button  className="button" type='button' id='privat' value={false} onClick={onMutate}>No</button>
        </div>*/}
         <p className='createInvitations__text'>Please choice a backgroundcolor for your Invitation</p>
        <Backdrop id='backgroundcolor' value={backgroundcolor}  options={["black","white", "yellow", "green","red","orange"]} onMutate={onMutate} multiple={false} className="createInvitations__input"/>
   <p className='createInvitations__text'>Updoad 2 Images , please only .jpg, .png, jpeg</p>
     <input className='createInvitations__input__datei' type='file' id='images' onChange={onMutate} max='2' min='2' accept='.jpg,.png,.jpeg'  multiple required/>
     </div>
   <div className="createInvitations__submit">
     <button className='button' type='submit'>Create Invitation</button>
     </div>
      </form>
    </div>
  )
}

export default CreateInvitations
