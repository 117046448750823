import React ,{useState} from 'react'
import { ChevronCompactDown, Trash3 } from 'react-bootstrap-icons'
import { ChevronCompactUp } from 'react-bootstrap-icons'
import { doc, deleteDoc } from "firebase/firestore";
import { db } from '../firebase.config';
import {toast} from 'react-toastify'
import Popup from './Popup';
import { XLg } from 'react-bootstrap-icons';

 function Myinvitations({data , id}) {
 const [clicked, setClicked] = useState(false)
 const [deleted, setdeleted] = useState(false)
 const [showPopup, setShowPopup] = useState(false)
 const [imgUrls, setImgUrls] = useState(null)


 var startDate = new Date(data.startTimeDate)
 var endDate = new Date(data.endTimeDate)
 startDate = startDate.getDate() + "." + startDate.getMonth() +1  + "." + startDate.getFullYear()
 endDate = endDate.getDate() + "." + endDate.getMonth() + 1 + "." + endDate.getFullYear()

const onClick = async() => {
   
    try {

        const docRef = doc(db,'Invitations', id)
        await deleteDoc(docRef)
        toast.success("Invitation is deleted")

        setdeleted(true)
    }catch(error){

        toast.error("Error with deleting")
    }
}

const popUp = (imgUrls) => {
    setImgUrls(imgUrls)
    setShowPopup(true)

    return(
        <Popup pic={imgUrls} data={data}/>

    )
}

const onClick2 = () => {
    setClicked((prevState) => 
    !prevState)
}

const close = () => {
    setShowPopup(false)
}

if(deleted){
    return(<div></div>)
}

  return (
    <div className='MyInvitation'>
<div className='MyInvitation__top'>
  <div onClick={() => popUp(data.imgUrls[0])}  className='MyInvitation__image'>
  <img className="Invitation__img" src={data.imgUrls[0]} alt="Uploaded picture"/>

  </div>
   <div className='MyInvitation__texte'>
     <p className='MyInvitation__textheading'>{data.name}</p>
    <p className='MyInvitation__text-up'>status: {data.status}</p>
   </div>     

   <button onClick={onClick} className='MyInvitation__button'><Trash3 size={25}/></button>
   
   <button onClick={onClick2} className='MyInvitation__button-expand'> {clicked ? <ChevronCompactUp size={20}/>: <ChevronCompactDown size={20}/>}</button>
   </div>
{ clicked && (
    <div className='MyInvitation__bottom'>
    <p className='MyInvitation__text'>category: {data.category}</p>
    <p className='MyInvitation__text'>music: {data.music}</p>
    <p className='MyInvitation__text'>more additions: {data.additions}</p>
    <p className='MyInvitation__text'>startdate: {startDate} </p>
    <p className='MyInvitation__text'>starttime: {data.startTimeTime}</p>
    <p className='MyInvitation__text'>enddate: {endDate} </p>
    <p className='MyInvitation__text'>endtime: {data.endTimeTime}</p>
    <p className='MyInvitation__text'>age: {data.age}</p>
     <p className='MyInvitation__text'>price: {data.admission}</p>
     <div onClick={() => popUp(data.imgUrls[1])} className='MyInvitation__image2' >
     <img className="MyInvitation__img" src={data.imgUrls[1]} alt="Uploaded picture"/>

     </div>
    </div>
)

}

{showPopup &&<div> <Popup pic={imgUrls} data={data}/><XLg size={50} className='popUp__button' onClick={close} /> </div>}
</div>
  )
}


export default Myinvitations