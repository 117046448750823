import React,{useState,useContext} from 'react'
import usePlacesAutocomplete from "use-places-autocomplete";
   import SearchContext from '../context/SearchContext';

   

 export default  function PlacesAutocomplete() {
    const [loading,setLoading] = useState(false)
    const {setGotClicked,search,setSearch,setEmpty} = useContext(SearchContext)
    

    const {
        ready,
        value,
        setValue,
        suggestions: {status,data}
            } = usePlacesAutocomplete();

        
const onClick = (search) => {
    setLoading(true)
    
    setEmpty(false)
   setSearch(search)
     
     setGotClicked(true)
     setLoading(false)
     setValue('')
}



 
  return (

<header className='search__header'>

        {!loading ? <div className='autocomplite-all'>
     
        <input className='autocomplite-all__search-bar marginbottom-tiny' value={value} onChange={(e) => setValue(e.target.value)} disabled={!ready}

        placeholder='search for an address'/>
        
         <div className="autocomplite">
            <ul className='autocomplite__list'>
                {status == "OK" && data.map(({description,place_id}) => <li className='search__Result' key={place_id} onClick={() =>onClick(description)}>{description}</li>)}
            </ul>    
            </div>
            </div>:<h2>loading...</h2> }

   
  </header>
    
  )
}

