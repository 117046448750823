import React,{useState} from 'react'

function Backdrop({id, value, options, onMutate, multiple, className}) {


  return (
    <div>
      <select id={id} multiple={multiple ? true : false} value={value} onChange={onMutate} className={className}>
         {options.map((option) => <option key={option} value={option}>{option}</option> )}
      </select>

    </div>
  )
}

export default Backdrop
