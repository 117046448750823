import React from 'react'

function PrivacyPolicy() {
  return (
    <div className='pp'>
        <h2 className='pp__heading'>Privacy Policy and Data security</h2>
        <h3 className='pp__headingSecound'>Data Collection Methods </h3>
        <p className='pp__paragraph'>We utilize and store your email address for authentication purposes and do not use it for advertising or any related activities. Additionally, we use Cookies for functionality on our website. 
            Furthermoree we use a firebase Account for storing user data </p>
            <p className='pp__paragraph'>All information that you enter will be publicly visible, everyone can read and store Data from our Website.</p>

        <p className='pp__paragraph'>Any images and text that you provide are stored in our database and processed solely for the functional purposes of our website. We do not utilize any of this information for other purposes</p>

        <h3 className='pp__headingSecound'>Use of Collected Information</h3>
        <p className='pp__paragraph'>We use Cookies for Google Analytics and to track user acceptance of the Cookie consent banner. The data collected through Cookies is used exclusively to enhance the functionality of our website and is not utilized for any other purposes.</p>

       <h3 className='pp__headingSecound'>Opting In and Out of Data Collection</h3>
       <p className='pp__paragraph'>Users have the option to decline the Cookie banner, thereby opting out of the use of Cookies. Furthermore, if a user solely utilizes our homepage with the search bar, no data is collected, even through Cookies.</p>

       <h3 className='pp__headingSecound'>Data Deletion Procedures </h3>
       <p className='pp__paragraph'>Cookies are automatically deleted after a certain period of time. Users also have the ability to decline the cookie banner to prevent data collection. Additionally, any information inputted by users is deleted from our server with no backup retention.</p>
    </div>
  )
}

export default PrivacyPolicy
