import {createContext, useState} from 'react'


const SearchContext = createContext()

export const SearchProvider = ({children}) =>{
  
    const [search,setSearch] = useState({})
    const [gotClicked, setGotClicked] = useState(false)
    const [empty, setEmpty] = useState(false)
 
  
    return <SearchContext.Provider value={{
    search,
    gotClicked,
    empty,
    setSearch,
    setGotClicked,
   setEmpty
    }}>
{children}
    </SearchContext.Provider>

}


export default SearchContext