import React from 'react'
import { useState } from 'react'
import { ChevronCompactDown } from 'react-bootstrap-icons'

function Termsofuse() {
   const [show, setShow] = useState(true)

    const onClick = () => {
    setShow(false)

    }

    if(!show){
        return <></>
    }

   return (
    <div className='termsofuse__background'>
 <div className='terms-of-use__container'>
    <div className='termsofuse'>
    <h1>Terms of use</h1>

<h2>Welcome to uenvited</h2>

<p>These Terms of Use (or "Terms") govern your use of the Website uenvited.com. When you click accept you agree to these terms. </p>
<p>Uenvited is provided by Adrian Dubois. These Terms of Use therefore constitute an agreement between you and Adrian Dubois.</p>

 

<h3>The Website</h3>
<ul>
<li><h4>Find nearby events </h4>
    <p> The Website allows you to search for nearby events such as parties.
    Everywhere in the world are Clubs/Events//Attractions and open parties with creative ideas and nice offers, to let people spend a nice evening and night.
    As the Website continues to grow we support more areas to search for parties.
   </p>
</li>
 <li>
   <h4>Add Events</h4>
   <p> As an authenticated user you may upload your own events and party, to invite people to your parties</p>
 </li>
</ul>

<ul>
   <li>
       <h3>
           Promoting safety,integrity and security on our Website
       </h3>
       <p>
           We develop and use tools that help to make their experiences positive and inclusive. We also have
           systems that work to combat abuse and violations of our Terms and policies, as well as harmful and deceptive behavior. We may suspend access to upload content on uenvited.com where we determine that you have frequently 
           provided manifestly illegal content. We also may share information about misuse or harmful content with law enforcement. {/* Privacy Policy */}

       </p>
   </li>
      <li>
       <h3>
           Developing and using technologies that help use consistently serve our growing community
       </h3>
       <p>
           We analyze information to serve our growing community. {/* Privacy Policy */}
       </p>
      </li>
      <li>
        <h3>
           Communicating with you
        </h3>
        <p>
        We may need to send you communications, like emails or in-product notices, to respond to you or inform you about any product-related issues, research, or our terms and policies.
        </p>
      </li>
      <li>
       <h3>Ensuring access to our Website</h3>
       <p>
           To operate our global Website, we must store and transfer data across our systems 
           around the world, including outside your country of residence. The use of this global infrastructure is necessary and essential to provide our Website. 
       </p>
      </li>
</ul>

{/*The previous stuff */}
 <h3>Your Commitments</h3>
 <p>In return for our commitment to provide the Website, we require you to make the below commitments to us.
 </p>




 {/*Was regelt die Vereinbarung was passiert wenn man zustimmt um was geht es hier, und was alles gehändelt wird Wird bei Instagram geregelt*/}


 {/* Was geht auf der Website */}
 {/* Detailliert  */}
 {/*Geltender Gerichtsstand*/}
 {/* Wer ich bin  In Instergram vorhanden*/}
 {/*Haftungsbeschränkung */}
 <h4>Who Can Use Our Website (uenvited.com)</h4>
 <p>We want our Website to be as open and inclusive as possible, but we also want it to be safe, secure, and in accordance with the law.
 So, we need you to commit to a few restrictions in order to secure yourself as well as other users.
 </p>
 <ul>
   <li>You must be at least 18 years old.</li>
   <li>You must not be prohibited from receiving any aspect of our Website under applicable laws.</li>
   <li>We must not have previously disabled your account for violation of law or any of our policies.</li>
   <li>You must not be a convicted sex offender.</li>
 </ul>
<h3>Restrictions that must be followed for uploading Content</h3>
<p>Restrictions on uploading content are crucial as they serve to enhance the security of our Website and promote inclusivity for all users. It is imperative to note that additional regulations governing content uploads can be found in the Terms of Use, and compliance with these provisions is equally essential. Therefore, it is imperative to adhere to all stipulated guidelines.
</p>
<ul>
   <li>
       <h4>All Location must be completely secure and necessarily additions like a security team  need to be added in case they are required</h4>
       <p>Every Invitation/Event/Party that gets uploaded has the potential to attract a diverse crowd, and ensuring their safety must be comprehensively guaranteed in accordance with applicable law </p>
   </li>
   <h4>All uploaded content is only allowed to be addressed to people above 18 years, and all Content, Texts, and Images must be address to adults only</h4>
   <h4>
       you must have permission from event holder, and in case of dispute you need evidence of the permission
   </h4>
   <h4>
       you must register the event if necessary and inform the relevant authorities about the celebration/event (as well the size and every needed information).
       Depending on the venue, this could trigger different additional regulations, and it is necessary to fully comply with them as well
   </h4>
</ul>
<h4>How you use our Website </h4>
<p>To increase the security on our Website we need our users to not only rely on the information on our Website and also follow the provided steps as well</p>
<ul>
   <li>
       Despite all the checks conducted on our Website, things that should not be allowed can slip through. You must additionally verify locations and invitations using for example an application that can identify the location as secure, thus confirming whether an invitation truly is what it claims to be
   </li>
</ul>
 <h4>How You Can't Use our Website (uenvited.com)</h4>
 <p>Providing a safe and open Website for a broad community requires that we all do our part.</p>
   <ul>
       <li>
           <h4>You can't impersonate others or provide inaccurate information.</h4>
           <p>You don't have to disclose your identity on Instagram, but you must provide us with accurate and up to date information (including registration information), which may include providing personal data. Also, you may not impersonate someone or something you aren't, and you can't create an account for someone else unless you have their express permission.
           </p>
       </li>
       <li>
           <h4>
           You can't do anything unlawful, misleading, or fraudulent or for an illegal or unauthorized purpose.
           </h4>
       </li>
       <li>
           <h4>
               You can't violate (or help or encourage others to violate) these Terms or our policies
           </h4>
       </li>
       <li>
           <h4>
           You can't do anything to interfere with or impair the intended operation of the Website.
           </h4>
           <p>
           This includes misusing any reporting, dispute, or appeals channel, such as by making fraudulent or groundless reports or appeals.
           </p>
       </li>
       <li>
           <h4>
           You can't attempt to create accounts or access or collect information in unauthorized ways.
           </h4>
           <p>
           This includes creating accounts or collecting information in an automated way without our express permission.
           </p>
       </li>
       <li>
           <h4>
           You can't sell, license, or purchase any account or data obtained from us or our Website.
           </h4>
           <p>
           This includes attempts to buy, sell, or transfer any aspect of your account (including your username); solicit, collect, or use login credentials or badges of other users; or request or collect Instagram usernames, passwords, or misappropriate access tokens.
           </p>
       </li>
       <li>
           <h4>
           You can't post someone else's private or confidential information without permission or do anything that violates someone else's rights, including intellectual property rights (e.g., copyright infringement, trademark infringement, counterfeit, or pirated goods).
           </h4>
           <p>
           You may use someone else's works under exceptions or limitations to copyright and related rights under applicable law. You represent your own or have obtained all necessary rights to the content you post or share.
           </p>
       </li>
       <li>
           <h4>You can't modify, translate, create derivative works of, or reverse engineer our products or their components.</h4>
       </li>
       <li>
           <h4>
           You can't use a domain name or URL in your username without our prior written consent.
           </h4>
       </li>

   </ul>
   
   <h3>Permissions You Give to Us.</h3>
   <p>As part of our agreement, you also give us permissions that we need to provide the Website.</p>
   <ul>
       <li>
           <h4>
           We do not claim ownership of your content, but you grant us a license to use it.
           </h4>
           <p>
           Nothing is changing about your rights in your content. We do not claim ownership of your content that you post on or through the Website and you are free to share your content with anyone else, wherever you want. However, we need certain legal permissions from you (known as a “license”) to provide the Website. When you share, post, or upload content that is covered by intellectual property rights (like photos or videos) on or in connection with our Website, you hereby grant to us a non-exclusive, transferable, sub-licensable, worldwide license to host, use, distribute, modify, run, copy, publicly perform or display, translate, and create derivative works of your content (consistent with your privacy and application settings) for purposes of making the Instagram Website available. This license will end when your content is deleted from our systems. You can delete content individually
           </p>
       </li>
   </ul>
   <ul>
       <li>
       If you select a username or similar identifier for your account, we may change it if we believe it is appropriate or necessary (for example, if it infringes someone's intellectual property or impersonates another user).
       </li>
       <li>
       If you use content covered by intellectual property rights that we have and make available in our Website (for example, images, designs, videos, or sounds we provide that you add to content you create or share), we retain all rights to our content (but not yours).
       </li>
       <li>
       You must obtain written permission from us or under an open source license to modify, create derivative works of, decompile, or otherwise attempt to extract source code from us.
       </li>
   </ul>
   <h4>Content Removal and Disabling or Terminating Your Account</h4>
   <ul>
       <li>We can remove or restrict any content you share on the Website if it violates these Terms of Use or our policies, or we are required to do so by law. When we remove or restrict content, we’ll let you know without delay about this and the reason for it and explain any options you have to request another review. If you request a review, you will be notified as to whether we will uphold the decision or restore or unrestrict the content or information.
           <p>This does not apply if and as long as we are prohibited from providing a notification, including details on the reason and the possibility to request a review for legal reasons. This also does not apply if and as long as such notification would be unreasonable, taking into account the interests of both parties.</p>
       </li>
       <li>
       If you violate these Terms of Use or our policies, if you repeatedly infringe other people's intellectual property rights, or where we are required to do so by law, we may – without prejudice of our termination right for good cause – completely or partially refuse to provide or stop providing the Website to you. In this case, we will inform you in advance about the reason and the kind and scope of such intended restrictions and give you the opportunity to comment on them. Thereafter, we will inform you whether or not we will adhere to the intended restrictions. This does not apply if and as long as we are prohibited from providing the notification, including details on the reason, the possibility to request a review, and, if applicable, the kind and duration of the limitation, for legal reasons. This also does not apply if and as long as the notification would be unreasonable, taking into account the interests of both parties.
       </li>
       <li>
       Our right to terminate for good cause remains unaffected. Good cause is specifically if a party violates obligations under these Terms, any laws, third party rights, or privacy policies, and the terminating party cannot be expected to continue the contractual relationship under consideration of all circumstances of the individual case and having weighed the interests of both parties up until the agreed time of termination or the expiry of a period of notice. A termination for good cause is possible only within a reasonable time frame after becoming aware of the violation.
       </li>
       <li>
       If the good cause is a breach of an obligation of these Terms, termination is permitted only after an unsuccessful granted cure duty has expired, or after an unsuccessful warning. However, a time limit for remedy and a warning are not necessary if the other side refuses to fulfill their obligations seriously and with finality or if special circumstances justify an immediate termination, having weighed the interests of both parties.
       </li>
       <li>
       If you believe your account has been terminated in error, or you want to disable or permanently delete your account, you can do this by writing an email. When you request to delete content, it will be deleted immediately from the Server.
       </li>
     <li>
     <h5>
       Child protection (Instant Delete without explanation)
     </h5>
     <p>
       Despite our imposition of an age restriction of 18 years and older for accessing our website and requirement that all content be intended for individuals above the age of 18, we employ additional systems to identify any content directed towards children. Should we discover any such content on our website, targeting minors, we promptly remove said content without delay or response. This encompasses not only content explicitly inviting children, but also any content that may inadvertently appeal to minors.
     </p>
     </li>
   </ul>
   <h3> Content will not be deleted immediately from the Server in the following situations:</h3>
   <ul>
       <li>
           where deletion is not possible due to technical limitations of our systems, in which case, we will contact our users.
       </li>
       <li>
           where deletion would restrict our ability to: 
           <ul>
               <li>
               investigate or identify illegal activity or violations of our terms and policies (for example, to identify or investigate misuse of our products or systems);
               </li>
               <li>
               protect the safety and security of our products, systems, and users;
               </li>
               <li>
               comply with a legal obligation, such as the preservation of evidence; or
               </li>
               <li>
               comply with a request of a judicial or administrative authority, law enforcement or a government agency;
               </li>
           </ul>
       </li>
       <li>
       in which case, the content will be retained for no longer than is necessary for the purposes for which it has been retained (the exact duration will vary on a case-by-case basis).
       </li>
   </ul>

     <h3>Our Agreement and What Happens if We Disagree</h3>
     <h4>Our Agreement.</h4>
      <ul>
       <li>If any aspect of this agreement is unenforceable, the rest will remain in effect.</li>
       <li>Any amendment or waiver to our agreement must be in writing and signed by use. 
           If we fail to enforce any aspect of this agreement, it will not be waiver.
       </li>
       <li>We reserve all rights not expressly granted to you </li>
      </ul>
      <h4>Who Has Rights Under this Agreement.</h4>
      <ul>
       <li>This agreement does not give rights to any third parties.</li>
       <li>You cannot transfer your rights or obligations under this agreement without our consent.</li>
       <li>Our rights and obligations can be assigned to others. For example, this could occur if our ownership changes 
           (as in a merger, acquisition, or sale of assets) or by law.</li>
      </ul>
      <h4>Who Is Responsible if Something Happens</h4>
      <ul>
      <li>By using our website, you do so at your own risk. Providing information about locations can be inaccurate and deceptive, potentially leading to unsafe or dangerous events and situations, for which you can't hold us responsible.
       </li>
       <li>
       Without limiting any of the above, we shall be liable for slight negligence only in the case of violation of an "essential" obligation under this agreement. "Essential" obligations in this sense are obligations necessary for the fulfillment of the agreement, the breach of which would jeopardize the purpose of the agreement in question, and which you can trust to be complied with. In these cases, liability is limited to typical and foreseeable damages; in other cases, there is no liability for slight negligence.
       </li>
      </ul>
    <h4>If a claim or dispute arises out of or relates to your use of the Website as consumer, both you and us agree that any such claim or dispute must resolved in a competent court in Fürstenfeldbruck under German law will apply without regard to conflict of law provisions</h4>

    <h3>Privacy Policy and Data security</h3>

    <h4>Data Collection Methods </h4>
     <p>We utilize and store your email address for authentication purposes and do not use it for advertising or any related activities. Additionally, we use Cookies for functionality on our website. 
       Furthermoree we use a firebase Account for storing user data</p>
     <p>All information that you enter will be publicly visible, everyone can read and store Data from our Website.</p>
     <p>Any images and text that you provide are stored in our database and processed solely for the functional purposes of our website. We do not utilize any of this information for other purposes</p>
    
     <h4>Use of Collected Information</h4>
     <p>We use Cookies for Google Analytics and to track user acceptance of the Cookie consent banner. The data collected through Cookies is used exclusively to enhance the functionality of our website and is not utilized for any other purposes.</p>
     
    <h4>Opting In and Out of Data Collection</h4>
    <p>Users have the option to decline the Cookie banner, thereby opting out of the use of Cookies. Furthermore, if a user solely utilizes our homepage with the search bar, no data is collected, even through Cookies.</p>
     
    <h4>Data Deletion Procedures </h4>
    <p>Cookies are automatically deleted after a certain period of time. Users also have the ability to decline the cookie banner to prevent data collection. Additionally, any information provided by users is deleted from our server with no backup retention.</p>
   
   <h3>Updating These Terms</h3>
   <p>We may change our Website and policies, and we may need to make changes to these Terms so that they accurately reflect our Website and policies. We will only make changes if the provisions are not appropriate anymore or if they are incomplete, and only if the changes are reasonable for you in consideration to your interests. Unless otherwise required by law, we will notify you of the changes and choices you have (for example, through our Website) at least 30 days before we update the Terms, and we will give you an opportunity to review them before they go into effect. Then, if you continue to use the Website, you will be bound by the updated Terms.</p>


        <button onClick={onClick} id="accept-button" className='termsofuse__button'>Accept</button>
    </div>
    <a href="#accept-button">
    <div className='terms-of-use__scroll-button'>
    
        <ChevronCompactDown color="white" size={40}/>
     </div>
     </a>
    </div>
    </div>
  )
}

export default Termsofuse
