import React from 'react'
import {useState} from 'react'
import {Link} from 'react-router-dom'
import { getAuth,sendPasswordResetEmail } from 'firebase/auth'
import {toast} from 'react-toastify'


function Forgotpassword() {
    const [email, setEmail] = useState('')

const onChange = (e) => {
setEmail(e.target.value)
}

const onSubmit = async(e) => {
    e.preventDefault()
    try{
    const auth = getAuth()
    await sendPasswordResetEmail(auth, email)
    toast.success('Email was send')


    }catch(error){

        toast.error('Could not send resetemail')
    }
}

  return (
    <>
<header className='header'>
  <h1 className='heading'>Forgot Password</h1>
</header>
    <form onSubmit={onSubmit} className='forgot-password__form'>
        <input type='email' placeholder='email' id='email' value={email}
        onChange={onChange} className='forgot-password__form-input marginbottom-medium margintop-small'/>
        <button className='button'>Send reset Link</button>
    </form>
    <div className='links margintop-small'>
    <Link to='/sign-in' className='link'>Sign In</Link>
    </div>
    </>
  )
}

export default Forgotpassword
