import React from 'react'

function Creditpage() {
  return (
    <div className='credit-page'>
      <h2 className='credit-page__heading'>On this page, you can see and visit pages, which helped to build our Website</h2>
       <p className='credit-page__text'> The ico from the Logo is downloaded from this Link       <a className='credit-page__link' href="https://icon-icons.com/">icon-icons</a>.
</p>
    </div>
  )
}

export default Creditpage
